

const dashboardRoutes = [
{
    path: '',
    name: 'admin-root',
    component: () => import('../views/Dashboard.vue'),
    meta: {
        title: 'Banner Insite - Dashboard Admin',
        permissions: ['system_admin','admin','manager','user']
    },
    sensitive: true,
    strict: true
},    
{
    path: 'dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
        title: 'Banner Insite - Dashboard Admin',
        permissions: ['system_admin','admin','manager','user']
    },
    sensitive: true,
    strict: true
},
{
    path: 'manage-client',
    name: 'manage-client',
    component: () => import('../views/systemAdmin/ManageClient.vue'),
    meta: {
        title: 'Banner Insite - Manage Clients',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
{
    path: 'manage-user',
    name: 'manage-user',
    component: () => import('../views/systemAdmin/ManageUser.vue'),
    meta: {
        title: 'Banner Insite - Manage Users',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
{
    path: 'manage-offerlogix',
    name: 'manage-offerlogix',
    component: () => import('../views/systemAdmin/ManageOfferlogix.vue'),
    meta: {
        title: 'Banner Insite - Manage Offerlogix',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
{
    path: 'generate-template',
    name: 'generate-template',
    component: () => import('../views/systemAdmin/GenerateTemplate.vue'),
    meta: {
        title: 'Banner Insite - Generate Template',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
{
    path: 'manage-templates',
    name: 'manage-templates',
    component: () => import('../views/systemAdmin/ManageTemplate.vue'),
    meta: {
        title: 'Banner Insite - Manage Templates',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
{
    path: 'manage-image',
    name: 'manage-image',
    component: () => import('../views/systemAdmin/ManageImage.vue'),
    meta: {
        title: 'Banner Insite - Manage Images',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
{
    path: 'manage-site',
    name: 'manage-site',
    component: () => import('../views/systemAdmin/ManageSite.vue'),
    meta: {
        title: 'Banner Insite - Manage Sites',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
{
    path: 'configure-assets',
    name: 'configure-assets',
    component: () => import('../views/systemAdmin/ConfigureAssets.vue'),
    meta: {
        title: 'Banner Insite - Configure Assets',
        permissions: ['system_admin']
    },
    sensitive: true,
    strict: true
},
// {
//     path: 'manage-client',
//     name: 'manage-client',
//     component: () => import('../views/systemAdmin/ManageClient.vue'),
//     meta: {
//         title: 'Banner Insite - Manage Client',
//     }
// },
{
    path: 'test',
    name: 'test',
    component: () => import('../views/systemAdmin/Test.vue'),
    meta: {
        title: 'Banner Insite - Test',
        
    }
},

    
]

export default dashboardRoutes