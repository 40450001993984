import { defineStore } from 'pinia'
import { ref, computed, watch} from 'vue'
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import router from '../routes/router'



export const useAppStore = defineStore('app', () => {
    //State
    const user = ref({});
    const clientId = ref(false);
    const selectedClientId = ref("");
    const isAuthenticated = ref(false);
    const tokenInterval = ref(false);
    const timeToExpiration = ref(0);
    const expireTime = ref(0);
    const sessionToken = ref(false);

    const loginPageMessage = ref(false);

    
    //Sidebar to toggle
    const sidebarExpanded = ref(true);

    const metaTitle = ref("");
    const metaDescription = ref("");
    const metaImage = ref("");

    
    //variables
    const options = { 
        sameSite: 'strict',
        secure: false,
        expires: '',
        domain: '',
        path: ''
    }
    

    //Actions
    function checkAuth() {
        isAuthenticated.value = false;
        let token = getTokenAuth()
        if(token){
            
            try {
                let userData = JSON.parse(localStorage.getItem("bannerinsiteUser"))
                let expireIn = calculateExpiration(token);
                if(userData?.userId && expireIn > 10000){
                    user.value = userData;
                    sessionToken.value = token;
                    clientId.value = userData.clientId;  
                    isAuthenticated.value = true;
                }
                
            } catch (error) {
                isAuthenticated.value = false;
                user.value = {};    
            }
        }
        return isAuthenticated.value === true;
    }

    function loginAuth(token, userObject){
        signoutAuth()
        let expire = calculateExpiration(token);
        
        options.expires = expire;
        Cookies.set("bannerToken", token, options)
        user.value = userObject;
        sessionToken.value = token;
        clientId.value = userObject.clientId;
        localStorage.setItem("bannerinsiteUser", JSON.stringify(userObject))
        isAuthenticated.value = true;
        startTokenInterval();
    }

    function signoutAuth(redirect = false){
        
        Cookies.remove("bannerToken", {path: options.domain, domain: options.path})
        user.value = {};
        clientId.value = false;
        localStorage.removeItem("bannerinsiteUser");
        sessionToken.value = false;
        isAuthenticated.value = false;
        stopTokenInterval();

        if(redirect)router.push({ name: 'sign-in'})
        
    }

    function getUserAuth(){
        if(!isAuthenticated.value) return {}
        
        return user;
    }

    function getTokenAuth(){
        return Cookies.get("bannerToken", options)    
    }

    function getApiTokenAuth(){
        return import.meta.env.VITE_API_TOKEN;    
    }

    function calculateExpiration(token){
        
        let dateInMillisecs = Date.now();
        let timeNowSeconds = Math.round(dateInMillisecs / 1000);
        const unixExpiration = jwtDecode(token).exp;
        let difference = unixExpiration - timeNowSeconds
        if(Math.sign(difference)){
            return difference * 1000;
        }

        return 0;
    }

    function checkBeforeRoute(){
        let token = getTokenAuth()
        let invalid = false;
        let time = 0;
        try {
            if(token){
                let timeInMilliseconds = calculateExpiration(token);
                if(!timeInMilliseconds){
                    invalid = true;
                }else{
                time = Math.round(timeInMilliseconds / 1000);  
                }

                if(!Math.sign(time) || time < 10){
                    console.log("time invalid", time);
                    invalid = true;
                }
            }
        } catch (error) {
            invalid = true;
        }

        if(invalid){
            loginPageMessage.value = "Your token expired. Please sign-in to authenticate your session.";
            signoutAuth(true);
            
        }
        
    }

    function startTokenInterval(){
        tokenInterval.value = setInterval(() => {
            
            if(!sessionToken.value)signoutAuth(true);
            let millisecs = calculateExpiration(sessionToken.value)
            if(!millisecs)signoutAuth(true);
            let inSeconds = Math.round(millisecs / 1000);
            if(!Math.sign(inSeconds) || inSeconds < 2)signoutAuth(true);
            timeToExpiration.value = inSeconds;
            
        }, 1000);
    }

    function stopTokenInterval(){
        timeToExpiration.value = 0;
        expireTime.value = 0;
        clearInterval(tokenInterval.value)
    }


    function toggleSidebar(){
        sidebarExpanded.value = !sidebarExpanded.value;
    }




    //Getters (computed)

    const watchForExpiration = computed(() => {
        let seconds = timeToExpiration.value % 60;
        let minutes = Math.floor(timeToExpiration.value / 60)
        return `${minutes}min ${seconds}sec`;
    })

    const getMetaTitle = computed(() => {
        return metaTitle.value;
    })

    watch(timeToExpiration, ()=> {
        if(isAuthenticated.value && Math.sign(timeToExpiration.value) && timeToExpiration.value < 2){
            loginPageMessage.value = "Your token expired. Please sign-in to authenticate your session.";
            signoutAuth(true);
        }
    });


    return  {   user, 
                clientId,
                isAuthenticated, 
                sidebarExpanded,
                metaTitle, 
                tokenInterval,
                timeToExpiration,
                loginPageMessage,
                selectedClientId,
                checkAuth, 
                loginAuth, 
                signoutAuth,
                getUserAuth, 
                toggleSidebar,
                getTokenAuth,
                getApiTokenAuth,
                startTokenInterval,
                checkBeforeRoute,
                getMetaTitle,
                watchForExpiration
            }
})